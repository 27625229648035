<template>
  <div class="hall-of-fame-page-wrapper align-self-start" ref="top">
    <div class="hall-of-fame__header desktop">
      <div class="left">
        <h1 class="page-title">Auctions</h1>
      </div>
      <div class="right">
        <!--        <div class="total-vp">You have: <span>{{ `${0}VP` }}</span></div>-->
        <button class="btn-blue w-full" @click="connectWallet()" v-if="isConnected === false">Connect wallet</button>  
        <button class="btn-blue w-full" @click="connectWallet()" v-else>{{ formatString(address) }}</button>
        <button class="btn-yellow w-full" @click="$router.push({path: '/add-initial-sale'})" v-if="isArtist === true">Add Initial Sale</button>
      </div>
    </div>
    <div class="mobile-header mobile justify-space-between">
      <div class="left">
        <h1 class="page-title">Auctions</h1>
      </div>
      <div class="right_mobile">
        <button class="btn-blue-wallet" @click="connectWallet()" v-if="isConnected === false">Connect wallet</button>  
        <button class="btn-blue-wallet" @click="connectWallet()" v-else>{{ formatString(address) }}</button> 
        <button class="btn-yellow" @click="$router.push({path: '/add-initial-sale'})">Add Initial Sale</button>
      </div>
    </div>
    <div class="hall-of-fame__about">
      <div class="about-wrapper">
        <h2 class="about-title">About Auctions</h2>
        <p>
          Royalty Rights Auction 

To participate in the auction, you’ll need a Concordium Wallet for secure transactions.<br/><br/>
1. Click on “Open Wallet” (or visit <a href="https://www.concordium.com/wallet" target="_blank">Concordium Wallet</a>).<br/>
2. Open your Concordium Wallet app.<br/>
3. Obtain your wallet address by selecting “Receive” in the app.<br/>
4. Add funds to your wallet (<a href="https://www.concordium.com/" target="_blank">Concordium website</a>).<br/>
5. Go to the UkrMusic Auction Page (link).<br/>
6. Click on “Connect Wallet”.<br/>
7. Once connected, navigate to the auction for the song you want to bid on.<br/>
8. Enter your bid amount and confirm the transaction through your Concordium Wallet.<br/>
        </p>
      </div>
      <div class="image-container">
        <img src="../assets/img/ukrmusic-singer.png" alt="ukrmusic">
      </div>
    </div>
    <div class="hall-of-fame__content">
      <div class="content-header">
        <div class="content-header__tabs">
          <ul class="content-tabs-wrapper">
            <li class="content-tab-item" :style="{
          background: contentTab === 'open' ? 'linear-gradient(90.41deg, rgba(0, 110, 233, 0.6) -2.47%, rgba(30, 150, 252, 0.36) 103.59%)' : '#FFFFFF',
          color: contentTab === 'open' ? '#FFFFFF' : '#0057B7',
          fontWeight: contentTab === 'open' ? 800 : 500
        }" @click="contentTabSwitchTo('open')">Open
            </li>
            <li class="content-tab-item" :style="{
          background: contentTab === 'finished' ? 'linear-gradient(90.41deg, rgba(0, 110, 233, 0.6) -2.47%, rgba(30, 150, 252, 0.36) 103.59%)' : '#FFFFFF',
          color: contentTab === 'finished' ? '#FFFFFF' : '#0057B7',
          fontWeight: contentTab === 'finished' ? 800 : 500
        }" @click="contentTabSwitchTo('finished')">Finished
            </li>
          </ul>
        </div>
        <div class="content-header__search">
          <div class="search-input">
            <input type="search" name="search" id="hall-of-fame-search" class="input" placeholder="Search"
                   v-model="searchText" @input="debounceRequest">
            <svg width="26" height="27" viewBox="0 0 26 27" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                  d="M11.9167 21.176C16.7031 21.176 20.5833 16.2125 20.5833 11.426C20.5833 6.63952 16.7031 2.75932 11.9167 2.75932C7.1302 2.75932 3.25 7.72285 3.25 12.5093C3.25 17.2958 7.1302 21.176 11.9167 21.176Z"
                  stroke="#0057B7" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M22.7496 23.3426L18.0371 18.6301" stroke="#0057B7" stroke-width="2" stroke-linecap="round"
                    stroke-linejoin="round"/>
            </svg>

          </div>
          <div class="filter" @click="$refs.HallOfFameFilterPopup.openPopup()">
            <span>Filter</span>
            <svg width="26" height="27" viewBox="0 0 26 27" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                  d="M3.25 7.60422C3.25 5.52662 4.93423 3.84239 7.01183 3.84239C7.01183 3.84239 15.1667 3.30111 19.0496 3.84239C21.0737 4.12456 22.2946 4.81908 22.75 6.55111C23.1507 8.07514 22.2708 9.66456 21.4398 10.3677C21.4398 10.3677 18.7933 12.0313 17.6261 13.5946C16.5613 15.021 15.9355 15.4772 15.7083 17.2427C15.6613 17.6083 15.6603 18.1946 15.6715 18.6981C15.6857 19.3331 15.6125 19.9717 15.3576 20.5535C15.1368 21.0578 14.8283 21.6654 14.4814 22.068C13.8186 22.8374 13.3869 23.4785 12.3784 23.5975C11.2345 23.7325 9.75 23.4109 9.75 22.2591C9.75 20.6341 10.1366 19.378 9.75 17.6002C9.43075 16.1321 9.17897 14.9459 8.35623 13.9824C8.13085 13.7185 7.89237 13.4655 7.68834 13.1848C7.29516 12.6437 6.58733 11.681 5.95833 10.8847C5.02592 9.70434 3.25 8.78732 3.25 7.60422Z"
                  stroke="#0057B7" stroke-width="2"/>
            </svg>

          </div>
          <!-- <div class="sort-by" @click="isSortOpen = !isSortOpen">
            <div>Sort by: <span style="text-transform: capitalize">{{ sortBy }}</span></div>
            <svg width="18" height="15" viewBox="0 0 18 15" fill="none" xmlns="http://www.w3.org/2000/svg"
                 :style="{transform: isSortOpen ? 'rotate(180deg)' : 'rotate(0deg)'}"
            >
              <path
                  d="M2.25 4.67599C3.73045 6.39584 4.34179 7.16878 5.7877 8.92342C6.13291 9.34235 8.25 11.3843 8.63267 11.0927C8.69755 11.0432 8.7255 11.0093 8.78528 10.9561C9.41015 10.4002 9.99667 9.71686 10.5 9.34263C11.8745 8.32066 12.4265 7.49822 13.6958 6.32187C14.2303 5.82655 14.6906 5.35257 15 4.67599"
                  stroke="#0057B7" stroke-width="2" stroke-linecap="round"/>
            </svg>
            <div class="sort-options" v-if="isSortOpen">
              <ul id="hall-of-fame-sort">
                <li @click.stop="sortByHandler('Recent', 'RECENT')">Recent</li>
                <li @click.stop="sortByHandler('More votes', 'MORE_VOTES')">More votes</li>
                <li @click.stop="sortByHandler('Less votes', 'LES_VOTES')">Less votes</li>
              </ul>
            </div>
          </div> -->
        </div>
      </div>
      <div class="content-body">
        <div class="body-wrapper" v-if="loadFlag">
          <AuctionsCardComponent v-for="(item, index) in auctions" :item="item" :key="item.auctionId"
                                   :getCase="getCase" :index="index" :identifier="identifier" @openSignInPopup="openSignInPopup"/>
        </div>
        <div class="body-wrapper-else" v-else>
          <PreLoader/>
        </div>
      </div>
    </div>
    <v-pagination
        v-if="auctions && auctions.length"
        v-model="page"
        :length="count"
        :total-visible="7"
        :color="'#0057B7'"
        prev-icon="<"
        next-icon=">"
        @input="onPagination"
        class="pagination my-5 mt-sm-10"
    ></v-pagination>
    <HallOfFameFilterPopup ref="HallOfFameFilterPopup" @submitFilter="submitFilter"/>
  </div>
</template>

<script>

import AuctionsCardComponent from "@/components/auctions/AuctionsCardComponent.vue";
import {mapActions, mapMutations, mapGetters} from "vuex";
import PreLoader from "@/components/elements/Preloader";
import HallOfFameFilterPopup from "@/components/popups/HallOfFameFilterPopup";
import debounce from "@/mixins/debouncer";
// import { BrowserWalletConnector } from '@concordium/wallet-connectors';
import { detectConcordiumProvider } from '@concordium/browser-wallet-api-helpers';

export default {
  name: "AuctionsView",
  components: { HallOfFameFilterPopup, PreLoader, AuctionsCardComponent},
  mixins: [debounce],
  data() {
    return {
      sortBy: 'createdAt',
      orderBy: 'RECENT',
      isSortOpen: false,
      auctions: [],
      loadFlag: false,
      identifier: 'hall-of-fame-audio',
      page: 1,
      count: null,
      pageSize: 9,
      searchText: '',
      era: '',
      genres: '',
      debounceRequest: null,
      isArtist: false
    }
  },
  computed: {
    ...mapGetters({
      provider: 'wallet/getProvider',
      address: 'wallet/getAddress',
      isConnected: 'wallet/getConnection'
    }),
    getCase() {
      let result
      if (this.contentTab === 'open') {
        result = 'case1'
      } else if (this.contentTab === 'finished') {
        result = 'case2'
      }
      return result
    },
    contentTab() {
      return this.$route.query.contentTab
    },
  },
  methods: {
    ...mapActions({
      getLegendSongsAmount: 'hallOfFame/getLegendSongsAmount',
      getAuctions: 'auctions/getAllAuctions',
      getAuctionsSearch: 'auctions/getAuctionsSearch',
      updateProvider: 'wallet/updateProvider',
      updateAddress: 'wallet/updateAddress',
      updateConnection: 'wallet/updateConnection',
      invokeErrorPopup: 'error/invokeErrorPopup'
    }),
    ...mapMutations({
      openSignInPopup: 'authentication/openSignInPopup'
    }),
    contentTabSwitchTo(name) {
      if (name === 'open') {
      this.$router.push({path: `/auctions`, query: {contentTab: 'open', page: '0'}}).catch(() => {})
      }
      if (name === 'finished') {
        this.$router.push({path: `/auctions`, query: {contentTab: 'finished', page: '0'}}).catch(() => {})
      }
    },
    async handleConnection(address){
      this.updateAddress(address)
      this.updateConnection(Boolean(address))
    },
    async connectWallet(){
      detectConcordiumProvider(100)
        .then((provider) => {
          provider.getSelectedChain()
            .then((genesisHash) => {
              if(genesisHash === '9dd9ca4d19e9393877d2c44b70f89acbfc0883c2243e5eeaecc0d1cd0503f478') {
                provider.requestAccounts()
                  .then((accounts) => {
                    this.handleConnection(accounts[0])
                  })
              } else {
                this.invokeErrorPopup("Change your network to Concordium Mainnet!")
              }
            })
        })
        .catch((err) => {
          this.invokeErrorPopup("You don't have Concordium Wallet. Please create it.")
          console.log("No wallet:" + err);
        })
    },
    async getWallet(){
      try {
        detectConcordiumProvider()
        .then((provider) => {
          provider.on('accountChanged', this.updateAddress);
          provider.on('accountDisconnected', () =>
              provider.getMostRecentlySelectedAccount().then(this.handleConnection)
          );
          provider.on('chainChanged', (chain) => {if(chain !== '9dd9ca4d19e9393877d2c44b70f89acbfc0883c2243e5eeaecc0d1cd0503f478'){
            this.handleConnection(null)
          }
          });
          // Check if you are already connected
          provider.getMostRecentlySelectedAccount().then(this.handleConnection);
        })
      } catch (error) {
          console.error('No wallet:', error);
          
      }
    },
    formatString(str) {
      if (str.length <= 4 + 5) {
        return str;
      }
      const start = str.slice(0, 4);
      const end = str.slice(-5);
      return `${start}...${end}`;
    },
    sortByHandler(name, value) {
      this.sortBy = name
      this.orderBy = value
      this.isSortOpen = false
    },
    async getAuctionsRequest() {
      let payload = {
        params: this.getRequestParams(this.page, this.pageSize, this.sortBy, this.era, this.genres, this.contentTab),
      }
      
      this.loadFlag = false
      
      let res = await this.getAuctions(payload)
      
      
      this.count = res.totalPages
      this.auctions = res.content
      this.loadFlag = true
    },
    async getAuctionsSearchRequest() {
      let payload = {
        params: this.getSearchRequestParams(this.searchText, this.page, this.pageSize, this.sortBy, this.era, this.genres, this.contentTab),
      }
      this.loadFlag = false
      if (this.searchText == '') {
        await this.getAuctionsRequest()
      } else {
        let res = await this.getAuctionsSearch(payload)
        
        this.count = res.totalPages
        this.auctions = res.content
        this.loadFlag = true
      }
    },
    filterByFieldValue (obj, field, value) {
      return Object.fromEntries(
        Object.entries(obj).filter(([nestedObj]) => nestedObj[field] === value)
      );
    },
    
    linkGen(pageNum) {
      return {
        path: `/auctions`,
        query: {page: pageNum, contentTab: this.$route.query.contentTab}
      }
    },
    getRequestParams(page, pageSize, sort, filter_era, filter_genre, status) {
      let params = {};

      if (page) {
        params["page"] = page - 1;
      }
      if (pageSize) {
        params["size"] = pageSize;
      }
      if (sort) {
        params["sort"] = sort;
      }
      // if (sortDirection) {
      //   params["sortDirection"] = sortDirection;
      // }
      if (filter_era) {
        params["filter_era"] = filter_era;
      }
      if (filter_genre){
        filter_genre.forEach(element => {
          if (params["filter_genreType"]) {
            params["filter_genreType"] += `,${element}`;
          } else {
            params["filter_genreType"] = element;
          }
        });
      }
      if (status == 'finished') {
        params["filter_auctionStatus"] = "FINISHED"
      } else if (status == 'open') {
        params["filter_auctionStatus"] = "OPEN"
      }

      return params;
    },
    getSearchRequestParams(searchText, page, pageSize, sort, filter_era, filter_genre, status) {
      let params = {};

      if (searchText) {
        params["keyword"] = searchText;
      }
      if (page) {
        params["page"] = page - 1;
      }
      if (pageSize) {
        params["size"] = pageSize;
      }
      if (sort) {
        params["sort"] = sort;
      }
      // if (sortDirection) {
      //   params["sortDirection"] = sortDirection;
      // }
      if (filter_era) {
        params["filter_era"] = filter_era;
      }
      if (filter_genre){
        filter_genre.forEach(element => {
          params["filter_genreType"] = element;
        });
      }
      if (status == 'finished') {
        params["filter_auctionStatus"] = "FINISHED"
      } else if (status == 'open') {
        params["filter_auctionStatus"] = "OPEN"
      }

      return params;
    },
    onPagination() {
      this.$router.push(this.linkGen(this.page)).catch(() => {
      })
    },
    submitFilter(data) {
      this.era = data.era
      this.genres = data.genres
      
      this.getAuctionsRequest()
    }
  },
  watch: {
    '$route.query.contentTab'(newVal) {
      if (newVal) {
        this.era = ''
        this.genres = ''
        if (this.$route.query.page === '1') {
          this.getAuctionsRequest()
        } else {
          this.$router.push(this.linkGen(1)).catch(() => {
          })
        }
      }
    },
    '$route.query.page': {
      deep: true,
      handler(newVal) {
        if (newVal) {
          this.page = +newVal
          this.getAuctionsRequest()
          if (this.count < newVal) {
            this.page = 1
          }
        } else {
          this.page = 1
        }
      }
    },
    '$store.wallet.state.provider': {
      handler() {
        this.getWallet()
      },
      immediate: true
    },
    orderBy(newVal) {
      if (newVal) {
        this.getAuctionsRequest()
      }
    }
  },
  created() {
    this.getAuctionsRequest()
    const user = JSON.parse(localStorage.getItem('user'))
    if (user.authorities[0] === 'ARTIST') {
      this.isArtist = true
    } else {
      this.isArtist = false
    }     
  },
  mounted() {
    this.debounceRequest = this.debounce(() => {
      this.getAuctionsSearchRequest()
    }, 500)
  }
}
</script>

<style scoped>
.hall-of-fame__header.desktop {
  display: flex;
}

.mobile {
  display: none;
}

.hall-of-fame__header {
  justify-content: space-between;
  align-items: center;
}

.right {
  display: flex;
  gap: 20px;
}

.right_mobile {
  display: flex;
  gap: 5px;
}

.total-vp {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 140%;
  color: #0057B7;
  margin-right: 20px;
}

.total-vp span {
  font-weight: 600;
}

.image-container {
  display: flex;
  align-items: center;
}

.hall-of-fame__about, .hall-of-fame__playlist {
  margin-top: 40px;
  background: #FFFFFF;
  padding: 40px;
  display: flex;
  border-radius: 10px;
}

.hall-of-fame__playlist {
  flex-direction: column;
}

.about-title {
  font-style: normal;
  font-weight: 800;
  font-size: 32px;
  line-height: 38px;
  color: #0057B7;
}

.about-wrapper {
  margin-right: 60px;
}

.about-wrapper p {
  margin-top: 30px;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 28px;
  color: #000000;
}

.hall-of-fame__about img {
  width: 180px;
}

.hall-of-fame__tabs {
  margin-top: 40px;
}

.tabs-wrapper {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: linear-gradient(90.41deg, rgba(0, 110, 233, 0.6) -2.47%, rgba(30, 150, 252, 0.36) 103.59%);
  border-radius: 10px;
  padding: 15px;
}

.tabs-item {
  width: calc(50% - 10px);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  padding: 13px 0;
  cursor: pointer;
}


.hall-of-fame__content {
  background: #FFFFFF;
  border-radius: 10px;
  margin-top: 40px;
}

.content-header {
  padding: 20px 40px;
  border: 1px solid #D9D9D9;
  border-radius: 10px 10px 0 0;
}

.content-tabs-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.content-tab-item {
  width: calc(50% - 10px);
  padding: 16px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  cursor: pointer;
}

.content-header__search {
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.search-input {
  position: relative;
  margin-right: 20px;
  flex: 1;
}

.search-input input {
  padding: 15px;
  width: 100%;
}

.search-input svg {
  position: absolute;
  top: 50%;
  right: 15px;
  transform: translateY(-50%);
}

.sort-by {
  position: relative;
  display: flex;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #0057B7;
  cursor: pointer;
}

.sort-by svg {
  position: relative;
  top: 2px;
  margin-left: 8px;
  cursor: pointer;
}

.sort-options {
  position: absolute;
  bottom: -135px;
  left: 0;
  background: #FFFFFF;
  padding: 15px 10px;
  border: 1px solid #D9D9D9;
  border-radius: 10px;
  width: 140px;
}

.sort-options ul li {
  margin-bottom: 15px;
  cursor: pointer;
}

.sort-options ul li:last-child {
  margin-bottom: 0;
}

.body-wrapper {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
}

.body-wrapper-else {
  display: grid;
  grid-template-columns: 1fr;
}


.filter {
  display: flex;
  align-items: center;
  margin-right: 15px;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #0057B7;
  cursor: pointer;
}

.filter svg {
  margin-left: 6px;
}

.playlist-header {
  display: flex;
}

.playlist-cover {
  width: 128px;
  height: 128px;
  object-fit: cover;
  border-radius: 10px;
  margin-right: 40px;
}

.playlist-songs-amount {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  color: #0057B7;
}

.header-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.playlist-name {
  font-style: normal;
  font-weight: 800;
  font-size: 24px;
  line-height: 28px;
  color: #0057B7;
  margin-bottom: 20px;
}

.btn-blue {
  border-radius: 10px;
}

.btn-blue-wallet {
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--primary-blue);
  border: 2px solid var(--primary-blue);
  padding: 13px 20px;
}

@media only screen and (max-width: 800px) {
  .body-wrapper {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }

  .hall-of-fame__about img {
    display: none;
  }

  .content-header {
    padding: 20px;
  }
}

@media only screen and (max-width: 600px) {
  .body-wrapper {
    display: grid;
    grid-template-columns: 1fr;
  }

  .hall-of-fame__about, .hall-of-fame__playlist {
    padding: 20px;
  }

  .content-header__search {
    flex-direction: column;
  }

  .search-input {
    width: 100%;
    margin-right: 0;
  }

  .sort-by {
    margin-top: 15px;
  }

  .hall-of-fame__about, .hall-of-fame__tabs {
    margin: 20px 15px;
  }

  .hall-of-fame__header.desktop {
    display: none;
  }

  .mobile {
    display: flex;
    gap: 5px;
  }

  .filter {
    margin-top: 15px;
  }

  .about-wrapper {
    margin-right: 0;
  }
}
</style>